import React from "react"
import Layout from "../markup/layout/layout"
import Seo from "../markup/layout/seo"
import { NewsletterSubscription } from "../components/Newsletter/NewsletterSubscription"
import { PageBanner } from "../markup/layout/PageBanner"
function unsubscribe() {
  const pageTitle = "Dezaboneaza-te"
  return (
    <Layout>
      <Seo title="Dezaboneaza-te" />
      <PageBanner
        pageTitle={pageTitle}
        isHomePage={false}
        pageInfo={{
          title: pageTitle,
          parent: { title: "", slug: "" },
          hasParent: false,
        }}
      />
      <NewsletterSubscription subscribing={false} />
    </Layout>
  )
}

export default unsubscribe
